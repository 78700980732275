import { default as numberFormat } from 'number-with-commas'

export const capitalizeFirstLetter = (text: string) => {
  if (!text) {
    return text
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const capitalizeAllFirstLettersOfWords = (text: string) => {
  if (!text) {
    return text
  }
  return text.split(' ').map(capitalizeFirstLetter).join(' ')
}

export const numberWithCommas = (x: number | string, isExemptZero?: true) => {
  // let value=
  if (isExemptZero === true) {
    if (x === undefined) {
      return ''
    }
    let retString: string = ''
    if (typeof x === 'string') {
      retString = numberFormat(parseFloat(x)?.toFixed(2)).split('.')[0]
    } else {
      retString = numberFormat(x?.toFixed(2)).split('.')[0]
    }
    return retString
  } else {
    if (x === undefined) {
      return ''
    }
    if (typeof x === 'string') {
      return numberFormat(parseFloat(x)?.toFixed(2))
    }
    return numberFormat(x?.toFixed(2))
  }
  // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}
