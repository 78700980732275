import ApiService from './service'
import * as http from './http'

export default class DiscountService extends ApiService {
  public async discountApproval(token: string) {
    let url = `${this.apiDomain}mobileapp/crm/getDiscountApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async discountAccept(token: string, status: string, comments: string) {
    let url = `${this.apiDomain}mobileapp/crm/discountApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token: token, type: status, comments: comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }
}
