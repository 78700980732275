import ApiService from './service'
import * as http from './http'

export default class ApprovalService extends ApiService {
  public async vinApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/getVINApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async vinAccept(
    token: string,
    type: string,
    extensionRequest: boolean
  ) {
    const url = `${this.apiDomain}mobileapp/crm/vinRequestApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, extensionRequest },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }

  public async refundApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/getRefundApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async refundAccept(token: string, type: string, comments: string) {
    const url = `${this.apiDomain}mobileapp/crm/refundRequestApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }

  public async orderCancelApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/getOrderCancelApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async orderCancelAccept(
    token: string,
    type: string,
    comments: string
  ) {
    const url = `${this.apiDomain}mobileapp/crm/orderCancelRequestApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }

  public async invoiceCancelApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/invoiceCancelApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async invoiceCancelAccept(
    token: string,
    type: string,
    comments: string
  ) {
    const url = `${this.apiDomain}mobileapp/crm/invoiceCancelStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }

  public async specialBookingApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/getSpecialApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async specialBookingAccept(
    token: string,
    type: string,
    comments: string
  ) {
    const url = `${this.apiDomain}mobileapp/crm/specialApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }

  public async leadLostApproval(token: string) {
    const url = `${this.apiDomain}mobileapp/crm/leadLostApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async leadLostAccept(token: string, type: string, comments: string) {
    const url = `${this.apiDomain}mobileapp/crm/leadLostStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type, comments },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }
}
