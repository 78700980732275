import ApiService from './service'
import * as http from './http'
import { ApiResponse } from '../models/response'
import { TestDriveDetails } from '../models/testDrive'

export default class TestDriveService extends ApiService {
  public async testDriveDetails(
    token: string
  ): Promise<ApiResponse<TestDriveDetails>> {
    let url = `${this.apiDomain}mobileapp/crm/getTestDriveDetails/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async updateTestDrive(data: {
    status: string
    token: string
    mileageIn?: string
    mileageOut?: string
  }) {
    const { status, token, mileageIn, mileageOut } = data
    let url = `${this.apiDomain}mobileapp/crm/testDriveUpdate/`

    // console.log(url)
    const response = http
      .post(
        url,
        { token, status, mileageIn, mileageOut },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }
}
