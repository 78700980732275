import ApiService from './service'
import * as http from './http'
import { ApiResponse } from '../models/response'
import { TechConsultationDetails } from '../models/techConsultation'

export default class TechConsultationService extends ApiService {
  public async techConsultationDetails(
    token: string
  ): Promise<ApiResponse<TechConsultationDetails>> {
    const url = `${this.apiDomain}mobileapp/crm/getTechConsultationApprovals/token/${token}`

    const resposne = await http.get(
      url,
      this.languageID,
      this.websiteID,
      this.subsiteID,
      this.token
    )
    return resposne?.data
  }

  public async techConsultationApproval(token: string, type: string) {
    const url = `${this.apiDomain}mobileapp/crm/techConsultationApprovalStatusUpdate/`
    // console.log(url)
    const response = http
      .post(
        url,
        { token, type },
        this.languageID,
        this.websiteID,
        this.subsiteID,
        undefined,
        token

        // 'multipart/form-data'
      )
      .then((res) => {
        // console.log(response)
        return res.status
      })
      .catch((err) => {
        // console.log(response)
        return err.response.status
      })
    return response
    // return response.status
  }
}
