import { Dispatch } from 'redux'
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload
} from '../../utils/action-utils'
import { api } from '../../api'
import { ProductModelStateModel } from './ViewModel'
export * from './ViewModel'

// initial state
const initialProductModelState: ProductModelStateModel = {
  isLoading: false,
  productModelsList: []
}

// reducer
export const productModelsReducer = (
  state: ProductModelStateModel = initialProductModelState,
  action: any
): ProductModelStateModel => {
  switch (action.type) {
    // combination
    case productModelActionTypes.PRODUCT_MODELS:
      return {
        isLoading: false,
        ...action.data
      }
    case productModelActionTypes.PRODUCT_MODELS_SET_LOADING:
      return {
        isLoading: action.data,
        productModelsList: []
      }
    case productModelActionTypes.PRODUCT_MODELS_CLEAR:
      return {
        isLoading: false,
        productModelsList: []
      }
    default:
      return state
  }
}

export const productModelActionTypes = {
  // COMBINATION DETAILS
  PRODUCT_MODELS: 'PRODUCT_MODELS',
  PRODUCT_MODELS_CLEAR: 'PRODUCT_MODELS_CLEAR',
  PRODUCT_MODELS_SET_LOADING: 'PRODUCT_MODELS_SET_LOADING'
}

// action creators & async actions
export const productModelsActions = {
  getProductModels:
    (onSuccess?: () => void, vehType?: string, modelYear?: any) =>
    async (dispatch: Dispatch) => {
      // dispatch({ type: types.GET_COURSES_REQUEST });
      try {
        dispatch({
          type: productModelActionTypes.PRODUCT_MODELS_SET_LOADING,
          data: true
        })
        const response = vehType
          ? await api.build.getProductModelList(vehType)
          : modelYear
          ? await api.build.getProductModelList(undefined, modelYear)
          : await api.build.getProductModelList()

        await dispatch({
          type: productModelActionTypes.PRODUCT_MODELS,
          data: response
        })
        if (onSuccess) {
          onSuccess()
        }
      } catch (error) {
        // serializeAndShowFormErrors(error);
        throw error
      }
    }
}

type ProductModelsSetLoadingActionType = IActionWithPayload<
  typeof productModelActionTypes.PRODUCT_MODELS_SET_LOADING,
  boolean
>

type ProductModelsResetActionType = IAction<
  typeof productModelActionTypes.PRODUCT_MODELS_CLEAR
>

export const productModelsResetSettingsAction =
  (): ProductModelsResetActionType => {
    return createAction(productModelActionTypes.PRODUCT_MODELS_CLEAR)
  }

export const productModelsSetLoadingAction = (
  data: boolean
): ProductModelsSetLoadingActionType => {
  return createActionWithPayload(
    productModelActionTypes.PRODUCT_MODELS_SET_LOADING,
    data
  )
}
