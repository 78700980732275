import * as http from './http'
import ApiService from './service'
import { TrackingData } from '../models/ai'

export default class AiService extends ApiService {
  public async sendTrackingCount(data: TrackingData) {
    const url = `${this.apiDomain}mobileapp/ai`

    const resposne = await http.post(
      url,
      data,
      this.languageID,
      this.websiteID,
      this.subsiteID
    )
    if (resposne.data?.meta?.responsecode === 200) {
      return {
        data: resposne?.data?.data,
        responsecode: resposne.data?.meta?.responsecode,
        status: resposne.data?.meta?.status,
        message: ''
      }
    } else {
      return {
        data: undefined,
        responsecode: resposne.data?.meta?.responsecode,
        status: resposne.data?.meta?.status,
        message: resposne.data?.meta?.message
      }
    }
  }
}
